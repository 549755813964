import React , {useState} from 'react';
import emailjs from 'emailjs-com';


const Result = () => {
    return (
        <p className="success-message">Tu mensaje ha sido enviado. Te contactaremos lo antes posible.</p>
    )
}
function FormStyle({props}) {
    const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_9xf5xce', 
            'template_jkzxgu5', 
            e.target, 
            'DqszjN0i8aN8THh2e'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    
    return (
        <div className="form-wrapper">
            <form action="" onSubmit={sendEmail}>
                <div className="rn-form-group">
                    <input type="text" name="fullname" placeholder="Nombre" required />
                </div>

                <div className="rn-form-group">
                    <input type="email" name="email" placeholder="Email" required />
                </div>


                <div className="rn-form-group">
                    <input type="text" name="subject" placeholder="Sujeto" required />
                </div>
                
                <div className="rn-form-group">
                    <textarea name="message" placeholder="Mensaje" required></textarea>
                </div>

                <div className="rn-form-group">
                    <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Enviar mensaje</button>
                </div> 

                <div className="rn-form-group">
                    {result ? <Result /> : null}
                </div> 
            </form>
        </div>
    )
}
export default FormStyle;