import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import ServiceList from "./service/ServiceList";


 


class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Servicios' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <React.Fragment>
                <div className="rn-page-title-area pt--10 pb--70 pb_md--50 pb_sm--50  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Servicios</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
                {/* End Breadcrump Area */}

                 {/* Start Service Area  */}
                 <div className="service-area creative-service-wrapper ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <span className="subtitle">Si está relacionado a la informática... ¡Podemos hacerlo!</span>
                                    <h2 className="title">Servicios que ofrecemos</h2>
                                    <p className="description">Ofrecemos una amplia gama de servicios, <br /> ofrecemos servicios integrales y personalizados.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="9" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */}
                
                <FooterTwo />


            </React.Fragment>
        )
    }
}
export default Service;