import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import Header from "../component/header/Header";
import CallAction from "../elements/callaction/CallAction";
import FooterTwo from "../component/footer/FooterTwo";
import { FiCheck, FiArrowLeft, FiStar } from "react-icons/fi";





class FullInvest extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Proyecto: Intro Maker' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--10 pb--60 pb_md--50 pb_sm--50  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Proyecto: Intro Maker</h2>
                                    <p><a href="/portafolio"><FiArrowLeft />Volver a Portafolio</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">¿Qué es?</h2>
                                                    <p className="description mt--30">Intro Maker es una web que crea animaciones con efectos especiales 2D/3D con el logo del usuario a partir de estilos prediseñados a los cuales se les puede personalizar los colores.</p>
                                                    <p className="description">Intro Maker se creó en el año 2013 y fue ganadora de varios premios a la innovación, siendo la primer web del mundo con esta funcionalidad.</p>
                                                    <p className="description">La web permite crear animaciones en 360p de manera gratuita incluyendo marca de agua o pagar por la versión Full HD (1080p) sin marca de agua.</p>
                                                    <p className="description">Se puede pagar por video o pagar subscripción mensual.</p>
                                                    <h6 className="description">Nuestro puntaje para este proyecto:</h6>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Diseño</span>
                                                        
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Programación</span>
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Presupuesto</span>
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="https://intromaker.net" target="_blank" rel="noopener noreferrer">Ir al sitio</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Nuestro Trabajo</h4> 
                                                        <p className="description mt--30">Intro Maker involucra la programación de servidores específicos en C# y VB.NET y también el desarrollo web que conecta al servidor.</p>
                                                        <p className="description mt--30">Diseñamos y desarrollamos una web que se conecta a servidores que se encargan de los renders de video. El sistema puede funcionar con E3 y abrir instancias por cada petición o trabajar con servidores estáticos de alto rendimiento.</p>
                                                        <p className="description mt--30">Realizamos innumerable cantidad de animaciones en After Effects que luego adaptamos y separamos en layers para que funcione de manera óptima con los servidores. </p>
                                                        <p className="description mt--30">Estas son los lenguajes de programación/librerías/tecnologías utilizadas en este proyecto:</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> HTML / CSS / Bootstrap</li>
                                                            <li><FiCheck /> Javascript / React / PHP</li>
                                                            <li><FiCheck /> VB.NET / C# para el servidor</li>
                                                            <li><FiCheck /> Visual Studio Code</li>
                                                            <li><FiCheck /> Adobe Photoshop / Illustrator</li>
                                                            <li><FiCheck /> Adobe After Effects</li>
                                                        </ul>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/intromaker-big-00.jpg" alt="intro maker"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='hRi8Toc0vUk' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/intromaker-big-01.jpg" alt="intro maker"/>
                                        </div>
                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/intromaker-big-02.jpg" alt="intro maker"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}




                <CallAction />  
                <FooterTwo />  
                


            </React.Fragment>
        )
    }
}
export default FullInvest;
