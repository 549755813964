// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';


// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from './component/PageScrollTop';

// Home layout

import Inicio from './home/Inicio';


// Dark Home Layout 
import DarkPortfolioLanding from './dark/PortfolioLanding';
import HomePortfolio from './dark/HomePortfolio';

// Element Layout
import Servicios from "./elements/Servicios";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contacto from "./elements/Contacto";
import PortfolioDetails from "./elements/PortfolioDetails";
import HeroTactics from "./elements/herotactics";
import FullInvest from "./elements/fullinvest";
import GreenBeardSeeds from "./elements/greenbeardseeds";
import Plantate from "./elements/plantate";
import IntroMaker from "./elements/intromaker";
import MathStrat from "./elements/mathstrat";
import ACCA from "./elements/acca";

import error404 from "./elements/error404";

// Blocks Layout
import Web from "./blocks/Portfolio";
import Design from "./blocks/PortfolioDesign";
import Video from "./blocks/PortfolioVideo";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";


import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>

                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Inicio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
                        


                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/servicios`} component={Servicios}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contacto`} component={Contacto}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/herotactics`} component={HeroTactics}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/fullinvest`} component={FullInvest}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/greenbeardseeds`} component={GreenBeardSeeds}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/plantate`} component={Plantate}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/intromaker`} component={IntroMaker}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/mathstrat`} component={MathStrat}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/acca`} component={ACCA}/>





                        {/* Blocks Elements  */}

                        <Route exact path={`${process.env.PUBLIC_URL}/web`} component={Web}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/Design`} component={Design}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/Video`} component={Video}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>
                        
                        
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();