import React, { Component } from "react";
import FormStyle from './FormStyle'

class ContactTwo extends Component{
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <span className="subtitle">Formulario de contacto</span>
                                <h2 className="title">Envianos un mensaje</h2>
                                <div className="im_address">
                                    <span>Whatsapp:</span>
                                    <a className="link im-hover" href="https://wa.me/+59899999999?text=Hola,%20estoy%20interesado%20en%20alguno%20de%20sus%20servicios">+598 99 999 999</a>
                                </div>
                                <div className="im_address mt--5">
                                    <span>Email:</span>
                                    <a className="link im-hover" href="mailto:contacto@loginek.com">contacto@loginek.com</a>
                                </div>
                            </div>
                            <FormStyle />
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="mb_md--30 mb_sm--30">
                            
                                <img src="/assets/images/about/about-12.jpg" alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;