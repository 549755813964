import React, { Component } from "react";

const Portfolio_image1 = <img src="/assets/images/portfolio/logo1.jpg" alt="Proyecto: Sonrisas" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/logo2.jpg" alt="Proyecto: Green Beard Seeds" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/logo3.jpg" alt="Proyecto: El Castillo" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/logo5.jpg" alt="Proyecto: Mónica Verdún" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/logo6.jpg" alt="Proyecto: CookHouse" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/logo7.jpg" alt="Proyecto: Dulce Aymara" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/logo8.jpg" alt="Proyecto: Xemoki Gaming" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/logo9.jpg" alt="Proyecto: On Off Urban Clothing" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/logo10.jpg" alt="Proyecto: ACCA" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/logo11.jpg" alt="Proyecto: Plantate Grow" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/logo12.jpg" alt="Proyecto: Ministerio Jesucristo Nuestro Rey" />;
const Portfolio_image13 = <img src="/assets/images/portfolio/logo13.jpg" alt="Proyecto: Math Strat" />;

const PortfolioListContent = [
    {
        image: Portfolio_image1,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image2,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image3,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image5,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image13,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image11,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image9,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image7,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image8,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },

    {
        image: Portfolio_image10,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },

    {
        image: Portfolio_image12,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    },
    {
        image: Portfolio_image6,
        category: 'Diseño / Logo',
        title: '',
        description: '',
        link: '#'
    }


]

class PortfolioListDesign extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={` ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    
                                        {value.image}
                                    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                        </div>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioListDesign;