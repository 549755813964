const BlogContent = [
    {
        images: '01',
        title: 'La diferencia entre tener una web y crear una marca',
        category: 'Branding'
    },
    {
        images: '02',
        title: 'Aprovechar la web al máximo para tu negocio',
        category: 'Marketing'
    },
    {
        images: '03',
        title: 'Cómo aprovechar las redes sociales al máximo',
        category: 'Redes sociales'
    },
    {
        images: '04',
        title: ' Getting tickets to the big show',
        category: 'Development'
    },
    {
        images: '05',
        title: 'A big ticket gone to be an interesting ',
        category: 'Management'
    },
    {
        images: '06',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
    {
        images: '07',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
]

export default BlogContent;