import React, { Component } from "react";

class Breadcrumb extends Component{
    render(){
        
        return(
            <React.Fragment>
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--10 pb--70 pb_md--50 pb_sm--50  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Portafolio</h2>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
            </React.Fragment>
        )
    }
}

export default Breadcrumb;

