import React from 'react';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import VideoPopup from "../blocks/VideoPopup";



const Portfolio = () => {
    
    return (
        
        <>
            <PageHelmet pageTitle='Portafolio' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'}   />
            {/* End Breadcrump Area */}


            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--60 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">Edición de video profesional</span>
                                        <h2 className="title">Videos Realizados</h2>
                                        <p className="description">Animación de logos, videos promocionales, comerciales para tv o Youtube, videoclips musicales, videos completos con voz en español o inglés y mucho más...</p>
                                    </div>
                                </div>
                            
                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Intro Maker - How it works" class="embed-responsive-item" src="https://www.youtube.com/embed/HF1CpjHH9EE" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Full Invest - Video promocional" class="embed-responsive-item" src="https://www.youtube.com/embed/zsKFDC9CDTY" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Animación de logo muro rompiéndose" class="embed-responsive-item" src="https://www.youtube.com/embed/54g8YlCZ6jY" allowfullscreen></iframe>
                                    </div>
                                </div>
                         
                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="La Hiperactina - Edición plana de video" class="embed-responsive-item" src="https://www.youtube.com/embed/J9THGB_V42w" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12  py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Animación para proyectar en evento social" class="embed-responsive-item" src="https://www.youtube.com/embed/qZArl0XWR38" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Video que explica cómo funciona IntroMaker.NET (en inglés)" class="embed-responsive-item" src="https://www.youtube.com/embed/hRi8Toc0vUk" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Animación de logo estilo ciencia ficción" class="embed-responsive-item" src="https://www.youtube.com/embed/39VoPLuw1bU" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Animación de logo estilo particulas" class="embed-responsive-item" src="https://www.youtube.com/embed/TkRJY3ryeY4" allowfullscreen></iframe>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Animación de logo estilo ciencia ficción" class="embed-responsive-item" src="https://www.youtube.com/embed/tUshXOzajQw" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 py-3 px-3">
                                    <div className="py-3 px-3 embed-responsive embed-responsive-16by9">
                                        <iframe title="Animación de logo estilo particulas" class="embed-responsive-item" src="https://www.youtube.com/embed/XSS75WBdDXY" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                
            </main>
            {/* End Page Wrapper  */}


            {/* Start Footer Area  */}
            <FooterTwo />
            {/* End Footer Area  */}
        </>
    )
}

export default Portfolio;