import React from 'react'
const CallAction = () => {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <h5>¡Estás a un click de convertir tu idea en realidad!</h5>
                            <h2>¿Lo vas a dejar pasar?</h2>
                            
                            <a className="btn-default btn-border btn-large btn-white mt--40" href="/contacto"><span>Comencemos</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;