import React ,{ Component }from "react";
import { FaCode, FaRegChartBar, FaRegComments, FaPalette, FaRegWindowRestore, FaHeartbeat, FaPenNib } from "react-icons/fa";

const ServiceList = [
    {
        icon: <FaHeartbeat />,
        title: 'Marketing Digital',
        description: 'Te armamos un plan de marketing específico y personalizado para tu negocio.'
    },
    {
        icon: <FaCode />,
        title: 'Desarrollo Web',
        description: 'Creamos aplicaciones web a medida, basadas en las necesidades de tus usuarios.'
    },
    {
        icon: <FaRegComments />,
        title: 'Redes Sociales',
        description: 'Cuando tenés un negocio existoso es fundamental delegar el manejo de redes sociales.'
    },
    { 
        icon: <FaPenNib />,
        title: 'Diseño Gráfico',
        description: 'Todos los negocios necesitan diseño gráfico profesional en algún momento.'
    },
    {
        icon: <FaRegWindowRestore />,
        title: 'Aplicaciones para Windows',
        description: 'Creamos aplicaciones para Windows en una amplia gama de lenguajes de programación.'
    },
    {
        icon: <FaPalette />,
        title: 'Diseño Web',
        description: 'La experiencia del usuario y la interface son fundamentales para aprovechar tu web al máximo.'
    },
    { 
        icon: <FaRegChartBar />,
        title: 'SEO: Posicionamiento Web',
        description: 'El posicionamiento web es fundamental, es lo que permite que los usuarios encuentren tu negocio.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
