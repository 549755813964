import React, { Component } from "react";
import { Link } from 'react-router-dom';

const Portfolio_image = <img src="/assets/images/portfolio/portfolio-1.jpg" alt="Proyecto: Intro Maker" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-2.jpg" alt="Proyecto: Plantate Grow" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-3.jpg" alt="Proyecto: Green Beard Seeds" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-4.jpg" alt="Proyecto: Full Invest" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-5.jpg" alt="Proyecto: Math Strat" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-6.jpg" alt="Proyecto: ACCA" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-7.jpg" alt="Proyecto: Green Beard Seeds" />;

const PortfolioListContent = [
    {
        image: Portfolio_image4,
        category: 'Diseño / Desarrollo',
        title: 'Full Invest',
        description: 'App descentralizada conectada a un smart contract.',
        link: '/fullinvest'
    },
    {
        image: Portfolio_image5,
        category: 'Diseño / Desarrollo',
        title: 'Hero Tactics',
        description: 'Web de juego NFT conectada a smart contract en la blockchain.',
        link: '/herotactics'
    },
    {
        image: Portfolio_image2,
        category: 'Diseño / Desarrollo / Redes Sociales',
        title: 'Plantate Grow',
        description: 'Web e-commerce con importante presencia en redes sociales.',
        link: '/plantate'
    },
    {
        image: Portfolio_image,
        category: 'Diseño / Desarrollo / Servidores',
        title: 'Intro Maker',
        description: 'Web que crea animaciones en video (intros) con tus logos.',
        link: '/intromaker'
    },
    {
        image: Portfolio_image7,
        category: 'Diseño / Desarrollo',
        title: 'Math Strat',
        description: 'Web de alquiler de señales de trading de Bitcoin.',
        link: '/mathstrat'
    },
    {
        image: Portfolio_image3,
        category: 'Diseño / Desarrollo',
        title: 'Green Beard Seeds',
        description: 'Web que funciona cómo catálogo de productos.',
        link: '/greenbeardseeds'
    },
    {
        image: Portfolio_image6,
        category: 'Diseño / Desarrollo',
        title: 'ACCA',
        description: 'Web de agrupación nacional con blog de noticias integrado.',
        link: '/acca'
    }

]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to="/portfolio-details">
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to="/portfolio-details">{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.link}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;