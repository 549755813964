import React, { Component , Fragment } from "react";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceList";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Helmet from "../component/common/Helmet";





const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--18',
        category: '',
        title: 'Tu negocio',
        description: 'Poseemos las herramientas, el conocimiento y el equipo necesario para mejorar el ingreso de tu negocio actual.',
        buttonText: '¿Querés saber cómo?',
        buttonLink: '/contacto'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--19',
        category: '',
        title: 'Redes Sociales',
        description: 'Manejamos tus redes sociales de manera profesional para que tú no lo tengas que hacer.',
        buttonText: 'Contact Us',
        buttonLink: '/contacto'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--33',
        category: '',
        title: 'Tu idea',
        description: 'Convertimos tu idea en realidad aplicando las tecnologías más adecuadas para tu startup.',
        buttonText: '¿Lo vas a dejar pasar?',
        buttonLink: '/contacto'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--11',
        category: '',
        title: 'SEO',
        description: '¿De qué sirve tu web si la gente no la encuentra con sus búsquedas?',
        buttonText: 'Nosotros te ayudamos',
        buttonLink: '/contacto'
    }


]

class Inicio extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){




        return(
            <Fragment> 
                <Helmet pageTitle="Inicio" />
                
                <Header />
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-10 col-sm-8">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="btn-default" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                


                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    <span className="subtitle">Si está relacionado a la informática... ¡Podemos hacerlo!</span>
                                    <h2 className="title">Servicios que ofrecemos</h2>
                                    <p className="description">Ofrecemos una amplia gama de servicios, <br /> ofrecemos servicios integrales y personalizados.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="9" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
                {/* End Service Area  */}



                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30 mb_sm--0">
                                        <span className="subtitle">Nuestros últimos trabajos</span>
                                        <h2 className="title">Últimos 3 trabajos realizados</h2>
                                        <p className="description">La belleza artística no consiste en representar una cosa bella, <br/> sino en la bella representación de una cosa.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="3" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="btn-default" href="/web"><span>Ver más trabajos</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}




{/* Start Blog Area 
                <div className="rn-blog-area pt--120 pb--80 bg_color--5">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Algunas publicaciones que pensamos que te interesarán</span>
                                    <h2 className="title">Publicaciones interesantes</h2>
                                    <p className="description">Encuentra artículos que te ayudarán a mejorar los ingresos de tu negocio.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--30">
                            {PostList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={i}>
                                    <div className="im_box">
                                        <div className="thumbnail">
                                            <Link to="/blog-details">
                                                <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <div className="content_heading">
                                                    <div className="category_list">
                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                    </div>
                                                    <h4 className="title">
                                                        <Link to="/blog-details">{value.title}</Link>
                                                    </h4>
                                                </div>
                                                <div className="content_footer">
                                                    <Link to="/blog-details" className="rn-btn btn-opacity">Leer artículo</Link>
                                                </div>
                                            </div>
                                            <Link className="transparent_link" to="/blog-details"></Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>

*/}

                {/* End Blog Area */}
                

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}

                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Inicio;