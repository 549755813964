import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { FiCheck, FiArrowLeft, FiStar } from "react-icons/fi";
import CallAction from "../elements/callaction/CallAction";


class HeroTactics extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Proyecto: ACCA' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--10 pb--60 pb_md--50 pb_sm--50  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Proyecto: ACCA</h2>
                                    <p><a href="/portafolio"><FiArrowLeft />Volver a Portafolio</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">¿Qué es?</h2>
                                                    <p className="description mt--30">ACCA (Agrupación de Cultivadores de Cannabis del Uruguay) es una asociación con fines políticos.</p>
                                                    <p className="description">En el sector "noticias" se puede estar actualizado sobre las leyes y decretos que afectan las actividades recreativas e industriales de este sector.</p>
                                                    <h6 className="description">Nuestro puntaje para este proyecto:</h6>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Diseño</span>
                                                        
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Programación</span>
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Presupuesto</span>
                                                        <h4><FiStar color="orange"/></h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="https://acca.org.uy" target="_blank" rel="noopener noreferrer">Ir al sitio</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Nuestro Trabajo</h4> 
                                                        <p className="description mt--30">Se trabajó de manera ad-honorem en el desarrollo de una web con formulario personalizado de inscripción.</p>
                                                        <p className="description mt--30">Se diseñó un logo y la web a partir de las necesidades planteadas por los administradores del sitio.</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> HTML / CSS / Bootstrap</li>
                                                            <li><FiCheck /> Javascript / PHP</li>
                                                            <li><FiCheck /> Visual Studio Code</li>
                                                            <li><FiCheck /> Adobe Photoshop / Illustrator</li>
                                                        </ul>     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">

                                        
                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/acca-big-01.jpg" alt="loginek - acca"/>
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/acca-big-02.jpg" alt="loginek - acca"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}



                <CallAction /> 
                <FooterTwo />  


            </React.Fragment>
        )
    }
}
export default HeroTactics;
