import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import { FiCheck, FiArrowLeft, FiStar } from "react-icons/fi";
import CallAction from "../elements/callaction/CallAction";




class Plantate extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Proyecto: Plantate Grow' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--10 pb--60 pb_md--50 pb_sm--50  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Proyecto: Plantate Grow</h2>
                                    <p><a href="/portafolio"><FiArrowLeft />Volver a Portafolio</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="row row--35 mb--50">
                                        <div className="col-lg-6">
                                            <div className="inner">
                                                <div className="section-title">
                                                    <h2 className="title">¿Qué es?</h2>
                                                    <p className="description mt--30">Plantate Grow es una web de e-commerce centralizda en la venta de todos los artículos necesarios para el cultivo de cannabis.</p>
                                                    <p className="description">¡Las redes sociales de Plantate llegan a más del 1% del país! (Uruguay).</p>
                                                    <h6 className="description">Nuestro puntaje para este proyecto:</h6>
                                                </div>
                                                <div className="portfolio-view-list d-flex flex-wrap">
                                                <div className="port-view">
                                                        <span>Diseño</span>
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Programación</span>
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Presupuesto</span>
                                                        <h4><FiStar color="orange"/><FiStar color="orange"/><FiStar color="orange"/></h4>
                                                    </div>
                                                </div>
                                                <div className="portfolio-details-btn mt--30">
                                                    <a className="btn-default btn-border" href="https://www.plantate.com.uy" target="_blank" rel="noopener noreferrer">Ir al sitio</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                                                <div className="inner">
                                                    <div className="section-title">
                                                        <h4 className="title">Nuestro Trabajo</h4>
                                                        <p className="description mt--30">Desarrollo de web de e-commerce con carrito de compras y diferentes métodos de pago. </p>
                                                        <p className="description mt--30">Diseño de logo y paleta de colores basada en el rubro.</p>
                                                        <p className="description mt--30">Creamos y manejamos las redes sociales, llegando a tener al 1% del país cómo seguidores y un alcance aún superior en las publicaciones.</p>
                                                        <ul className="list-style--1">
                                                            <li><FiCheck /> Diseño en HTML / CSS</li>
                                                            <li><FiCheck /> Programación en PHP / Laravel</li>
                                                            <li><FiCheck /> Base de Datos MySQL</li>
                                                            <li><FiCheck /> Marketing y manejo de Redes Sociales</li>
                                                            <li><FiCheck /> Fotos en Photoshop </li>
                                                            <li><FiCheck /> Logo en Illustrator</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/plantate-big-00.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='tUshXOzajQw' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/plantate-big-01.jpg" alt="loginek - herotactics"/>
                                        </div>

                                        <div className="thumb">
                                            <img src="/assets/images/portfolio/plantate-big-02.jpg" alt="loginek - herotactics"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

              

                <CallAction /> 
                <FooterTwo />  


            </React.Fragment>
        )
    }
}
export default Plantate;
