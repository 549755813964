import React from 'react';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import PortfolioListDesign from "../elements/portfolio/PortfolioListDesign";



const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Portafolio' />

            {/* Start Header Area  */}
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--60 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <span className="subtitle">Somos el big bang de tu emprendimiento</span>
                                        <h2 className="title">Diseños Realizados</h2>
                                        <p className="description">Te presentamos una pequeña muestra de trabajos innovadores realizados.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioListDesign styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="12" />
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                
            </main>
            {/* End Page Wrapper  */}


            {/* Start Footer Area  */}
            <FooterTwo />
            {/* End Footer Area  */}
        </>
    )
}

export default Portfolio;