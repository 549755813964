import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FaWhatsappSquare , FaMailBulk } from "react-icons/fa";
import ContactTwo from "../elements/contact/ContactTwo";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";




class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contacto' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--10 pb--70 pb_md--50 pb_sm--50  bg_image bg_image--4"  data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">¡Estás a un paso!</h2>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title mb--30 text-center">
                                    <span className="subtitle">Contactanos de manera directa</span>
                                    <h2 className="title">Contacto Rápido</h2>
                                    <p className="description">Si tienes un proyecto y/o precisas ayuda con algo relacionado a la informática envianos un mensaje.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaWhatsappSquare />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contactar por Whatsapp:</h4>
                                        <p><a href="https://wa.me/+59899999999?text=Hola,%20estoy%20interesado%20en%20alguno%20de%20sus%20servicios">+598 99 999 999</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FaMailBulk />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contactar por Email:</h4>
                                        <p><a href="mailto:contacto@loginek.com">contacto@loginek.com</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}



                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactTwo />
                </div>
                {/* End Contact Page Area  */}




                
                <FooterTwo />
            </React.Fragment>
        )
    }
}
export default Contact