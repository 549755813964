import React, { Component } from "react";
import ModalVideo from 'react-modal-video';


class VideoPopup extends Component {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render() {
        return (
            <>
                {/* Start Page Wrapper  */}
                <main className="page-wrapper">
                    {/* Start Video Area  */}
                    <div className="rn-section ptb--120 bg_color--1">
                        <div className="container">
                             <div className="row mt_dec--30 sercice-details-content align-items-center">
                                <div className="col-lg-4 col-md-6 col-12 mt--30">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Service Images"/>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='HF1CpjHH9EE' onClose={() => this.setState({isOpen: false})} />
                                        <button className="video-popup position-top-center theme-color md-size" onClick={this.openModal}><span className="play-icon"></span></button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* End Video Area  */}
                    <div className="rn-section ptb--120 bg_color--1">
                        <div className="container">
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                    <div className="thumb position-relative">
                                        <img className="w-100" src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Service Images"/>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='zsKFDC9CDTY' onClose={() => this.setState({isOpen: false})} />
                                        <button className="video-popup position-top-center theme-color md-size" onClick={this.openModal}><span className="play-icon"></span></button>
                                    </div>
                            </div>
                        </div>
                    </div>
                    {/* End Video Area  */}



                </main>
                {/* End Page Wrapper  */}

              
            </>
        )
    }
}

export default VideoPopup;